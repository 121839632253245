import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faCircle,
  faExclamationTriangle,
  faBellSlash,
  faCompress,
  faMagnifyingGlassMinus,
} from '@fortawesome/free-solid-svg-icons';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { Fab } from '@mui/material';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import { cosmosClient } from '../utils/eventstorage';

import '../styles/DeviceAssignment.css';

const formatLastShiftTime = (lastShiftTime, shiftStatus, timeInShift, isOffline, isSmartRecordingOff, isRecording) => {
  if (shiftStatus) {
    const formattedTime = formatTimeInShift(timeInShift);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {formattedTime ? `Now (${formattedTime})` : 'Now'}
        {isOffline && (
          <Tooltip title="Connection Lost" placement="top">
            <SignalWifiOffIcon style={{ marginLeft: '4px', color: 'gray', fontSize: '0.875rem' }} />
          </Tooltip>
        )}
        {!isRecording && isSmartRecordingOff && (
          <Tooltip title="Smart recording off because of poor connection" placement="top">
            <FontAwesomeIcon icon={faBellSlash} style={{ marginLeft: '4px', color: 'gray', fontSize: '0.875rem' }} />
          </Tooltip>
        )}
      </div>
    );
  }

  if (!lastShiftTime) return '';

  // Handle the case where lastShiftTime is already a Date object
  const date = lastShiftTime instanceof Date ? lastShiftTime : new Date(lastShiftTime);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error('Invalid date:', lastShiftTime);
    return '';
  }

  const now = new Date();
  if (date > now) return 'Future date';
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const formatRecordingTime = (time) => {
  if (time === null || time === undefined) return 'Recording now';
  if (time < 60) return `${Math.round(time)}s`;
  if (time < 3600) return `${Math.round(time / 60)}m`;
  return `${Math.round(time / 3600)}h`;
};

const GuardActionBanner = ({ devices: initialDevices }) => {
  const [state, setState] = useState(2);
  const [message, setMessage] = useState('Device is off');
  const [isVisible, setIsVisible] = useState(true);
  const [deviceSettings, setDeviceSettings] = useState({
    buttonType: 'MULTI_TOUCH',
    recordingPolicy: 'User',
  });
  const previousMessageRef = useRef('Device is off');

  useEffect(() => {
    const fetchDeviceSettings = async () => {
      try {
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: devices } = await devicesContainer.items
          .query({
            query: 'SELECT c.buttonType, c.recordingPolicy FROM c WHERE c.deviceId = @deviceId',
            parameters: [{ name: '@deviceId', value: initialDevices[0].deviceId }],
          })
          .fetchAll();

        console.log('devices config: ', devices);

        if (devices.length > 0) {
          setDeviceSettings({
            buttonType: devices[0].buttonType || 'MULTI_TOUCH',
            recordingPolicy: devices[0].recordingPolicy || 'User',
          });
        }
      } catch (error) {
        console.error('Failed to fetch device settings:', error);
      }
    };

    fetchDeviceSettings();
  }, [initialDevices]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newState = (state + 1) % 16;
      const newMessage = getMessage(newState, deviceSettings);

      if (newMessage !== previousMessageRef.current) {
        setIsVisible(false); // Start fade out
        setTimeout(() => {
          setState(newState);
          setMessage(newMessage);
          previousMessageRef.current = newMessage;
          setIsVisible(true); // Start fade in
        }, 100); // Wait for 0.1s fade out to complete
      } else {
        setState(newState);
        setMessage(newMessage);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [state, deviceSettings]);

  const getLogoClass = () => {
    if (state < 4) return 'black';
    if (state < 7) return 'green';
    if (state < 13) return 'red';
    if (state < 16) return 'green';
    return 'black';
  };

  const getRecordingControlText = () => {
    switch (deviceSettings.buttonType) {
      case 'SINGLE_TAP':
        return 'Tap';
      case 'DOUBLE_TAP':
        return 'Double tap';
      case 'MULTI_TOUCH':
        return 'Two-finger hold';
      default:
        return 'Tap';
    }
  };

  const getMessage = () => {
    const isShiftRecording = deviceSettings.recordingPolicy === 'Shift';

    switch (state) {
      case 0:
      case 1:
        return 'Device is off';
      case 2:
      case 3:
      case 4:
        return 'Hold to start shift';
      case 5:
      case 6:
      case 7:
        if (isShiftRecording) {
          return 'Recording starts automatically';
        } else {
          return `${getRecordingControlText()} to start recording`;
        }
      case 8:
      case 9:
      case 10:
        return 'Recording in progress';
      case 11:
      case 12:
      case 13:
        if (isShiftRecording) {
          return 'Hold to end shift and recording';
        } else {
          return `${getRecordingControlText()} to end recording`;
        }
      case 14:
      case 15:
        return 'Hold to end shift';
      default:
        return '';
    }
  };

  return (
    <div className="guard-action-banner">
      <div className="logo-container">
        <img src="/plix_logo_red.svg" alt="Plix Logo" className={`plix-logo ${getLogoClass()}`} />
      </div>
      <div className="text-container">
        <span className={`action-text ${isVisible ? 'fade-in' : 'fade-out'}`}>{message}</span>
      </div>
    </div>
  );
};

const DeviceAssignment = ({
  handleDeviceClick,
  devices,
  deviceShifts,
  updateDeviceAssignment,
  userRole,
  isMobile,
  userId,
}) => {
  const [rows, setRows] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const isGuard = userRole === 'guard';
  const showModeColumn = userId !== 'auth0|669fed96e6d5fdc532aedf04';

  const devicesWithShiftData = useMemo(() => {
    return devices.map((device) => ({
      ...device,
      ...deviceShifts[device.deviceId],
    }));
  }, [devices, deviceShifts]);

  const sortedRows = useMemo(() => {
    return [...devicesWithShiftData].sort((a, b) => {
      if (a.shiftStatus && !b.shiftStatus) return -1;
      if (!a.shiftStatus && b.shiftStatus) return 1;
      if (a.shiftStatus && b.shiftStatus) {
        return (a.timeInShift || 0) - (b.timeInShift || 0);
      }
      const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
      const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
      return bTime - aTime;
    });
  }, [devicesWithShiftData]);

  useEffect(() => {
    const sortedRows = [...devicesWithShiftData].sort((a, b) => {
      if (a.shiftStatus && !b.shiftStatus) return -1;
      if (!a.shiftStatus && b.shiftStatus) return 1;
      if (a.shiftStatus && b.shiftStatus) {
        return (a.timeInShift || 0) - (b.timeInShift || 0);
      }
      const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
      const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
      return bTime - aTime;
    });

    setRows(sortedRows);
  }, [devicesWithShiftData]);

  const handleRowClick = (params) => {
    const deviceId = params.row.deviceId;
    if (deviceId) {
      handleDeviceClick(deviceId, [params.row.latitude, params.row.longitude]);
    } else {
      console.error('Device ID is missing in the row data');
    }
  };

  const columns = [
    {
      field: 'assignedTo',
      headerName: 'Owner',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div style={{ fontSize: '0.875em' }}>{params.value || params.row.deviceName || 'Unassigned'}</div>
        </div>
      ),
    },
    {
      field: 'lastShiftTime',
      headerName: 'Last Shift',
      width: 240,
      renderCell: (params) => (
        <div style={{ fontSize: '0.875em', display: 'flex', alignItems: 'center' }}>
          {formatLastShiftTime(
            params.value,
            params.row.shiftStatus,
            params.row.timeInShift,
            params.row.isOffline,
            params.row.isSmartRecordingOff,
            params.row.isRecording
          )}
          {params.row.shiftStatus && !params.row.isRecording && (
            <Tooltip title={`In shift`} placement="top">
              <FontAwesomeIcon icon={faCircle} className="shift-dot" />
            </Tooltip>
          )}
          {params.row.isRecording && (
            <Tooltip title={`Recording now`} placement="top">
              <FontAwesomeIcon icon={faCircle} className="recording-dot" />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  console.log('rows: ', rows);

  return (
    <div className={`device-assignment-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div style={{ height: 400, width: '100%', paddingBottom: isMobile ? '80px' : 'none' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.deviceId}
          onRowClick={handleRowClick}
          style={{ width: '100%', height: '100%' }}
          components={{
            Header: (props) => <div {...props} className="custom-header" />,
          }}
          componentsProps={{
            columnHeader: {
              style: {
                height: 30,
                fontSize: '0.875rem',
                lineHeight: '30px',
              },
            },
          }}
          hideFooter={rows.length <= 100}
          hideFooterPagination={rows.length <= 100}
        />
      </div>
      {isMobile && showModeColumn && <GuardActionBanner devices={devices} />}
    </div>
  );
};

export default DeviceAssignment;
