import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../styles/Reports/ReportCard.css';

const ReportCard = ({ report, onClick, onDelete, isMobile }) => {
  if (!report) {
    return null;
  }

  const { authorEmail, assignedTo, lastUpdated, reviewed } = report;

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(report.reportId);
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <div className={`report-card ${!reviewed ? 'unreviewed' : ''}`} onClick={onClick}>
      <div className="report-card-left">
        <div className="report-card-info">
          <div className="report-assigned-to">{assignedTo || 'Unassigned'}</div>
          <div className="report-timestamp">
            <span className="report-time">{formatTime(lastUpdated)}</span>
            <span className="report-date">{formatDate(lastUpdated)}</span>
          </div>
          {!isMobile && <div className="report-author">{authorEmail}</div>}
        </div>
        {!reviewed && <div className="unreviewed-badge">Unreviewed</div>}
      </div>
      {!isMobile && (
        <div className="report-card-right">
          <button className="delete-button" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportCard;
