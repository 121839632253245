import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import App from './components/App';
import Home from './views/Home';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthFlowProvider, useAuthFlow } from './contexts/AuthFlowContext';
import * as Sentry from '@sentry/react';
import SharedVideo from './components/SharedVideo';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/redisland\.plix\.ai/, /^https:\/\/blueisland\.plix\.ai/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error && error.message) {
      event.extra = { ...event.extra, errorMessage: error.message };
    }
    return event;
  },
});

const MAX_AUTH_RETRIES = 3;
const authRetries = new Map();

const AppSelector = () => {
  const { isAuthenticated, user, isLoading, error, logout } = useAuth0();
  const { hasStarted } = useAuthFlow();

  useEffect(() => {
    const userId = user?.sub;
    if (userId) {
      const retries = authRetries.get(userId) || 0;
      if (retries >= MAX_AUTH_RETRIES) {
        logout({ returnTo: window.location.origin });
        return;
      }
      authRetries.set(userId, retries + 1);
    }
  }, [user]);

  if (window.location.pathname.startsWith('/sharedvideo/')) {
    return (
      <Router>
        <Routes>
          <Route path="/sharedvideo/:sharedVideoId" element={<SharedVideo />} />
        </Routes>
      </Router>
    );
  }

  if (error) {
    console.error('Authentication Error: ', error);
    return <div>Error during authentication</div>;
  }

  if (isLoading || !hasStarted) {
    return <Home />;
  }

  if (isAuthenticated) {
    if (user) {
      return <App isAuthenticated={isAuthenticated} user={user} logout={logout} />;
    }
  }
  return <Home />;
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}`}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid profile email',
    }}
  >
    <AuthFlowProvider>
      <ErrorBoundary>
        <AppSelector />
      </ErrorBoundary>
    </AuthFlowProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
