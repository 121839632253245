import React, { useEffect, useRef, forwardRef, useState, useCallback } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import '../styles/VideoPlayer.css';
import { trackVideoPlayed } from '../utils/analytics';

const VideoPlayer = forwardRef(
  ({ videoFileName, markers, token, preStart = 10, onPlayerUpdate, transcriptUrl, initialTime }, ref) => {
    const [captionsAvailable, setCaptionsAvailable] = useState(false);
    const plyrInstanceRef = useRef(null);
    const intervalRef = useRef(null);

    const url =
      token && videoFileName
        ? `https://plixvideostorage.blob.core.windows.net/videos-whole/${videoFileName}?${token}#t=${markers.length ? Math.max(0, markers[0].time - preStart) : 0}`
        : null;
    console.log('url', url);
    const transcriptUrlWithToken = transcriptUrl
      ? transcriptUrl + `?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`
      : `https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${videoFileName?.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`;

    const videoSource = {
      type: 'video',
      sources: [{ src: url, type: 'video/mp4' }],
      tracks: captionsAvailable
        ? [{ kind: 'captions', label: 'English', srclang: 'en', src: transcriptUrlWithToken }]
        : [
            {
              kind: 'captions',
              label: 'English',
              srclang: 'en',
              src: 'data:text/vtt,WEBVTT\n\n00:00:00.000 --> 00:00:03.000\n<i>Transcription in progress...</i>',
            },
          ],
    };

    const plyrOptions = {
      markers: { enabled: true, points: markers.sort((a, b) => a.time - b.time) },
      controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
      ],
      captions: { active: captionsAvailable, update: true, language: 'en' },
      crossorigin: 'anonymous',
      blankVideo: '',
      ratio: '1:1',
    };

    console.log('markers: ', markers);

    // const cleanupAndDestroy = useCallback(() => {
    //   console.error('PLYR: Player instance became undefined, cleaning up...');
    //   if (intervalRef.current) {
    //     clearInterval(intervalRef.current);
    //     intervalRef.current = null;
    //   }
    //   if (plyrInstanceRef.current && plyrInstanceRef.current.destroy) {
    //     plyrInstanceRef.current.destroy();
    //   }
    //   plyrInstanceRef.current = null;
    //   onError('Player instance became undefined');
    // }, [onError]);

    const checkPlayerState = useCallback(() => {
      const plyrInstance = plyrInstanceRef.current;
      try {
        if (plyrInstance?.currentTime != null && plyrInstance?.paused != null) {
          const newState = {
            currentTime: plyrInstance.currentTime ?? 0,
            isPlaying: !plyrInstance.paused,
          };
          if (typeof onPlayerUpdate === 'function') {
            onPlayerUpdate(newState);
          }
          console.log(`PLYR: Current time: ${newState.currentTime}`);
        }
      } catch (error) {
        console.error('Error checking player state:', error);
      }
    }, [onPlayerUpdate]);

    useEffect(() => {
      let isMounted = true;

      const setupInterval = () => {
        if (isMounted && plyrInstanceRef.current) {
          console.log('PLYR: Player is ready');
          if (initialTime != null) {
            plyrInstanceRef.current.currentTime = initialTime;
          }
          intervalRef.current = setInterval(checkPlayerState, 1000);
        }
      };

      // Delay the setup slightly to ensure the Plyr instance is fully initialized
      const timeoutId = setTimeout(setupInterval, 100);

      return () => {
        isMounted = false;
        clearTimeout(timeoutId);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, [initialTime, checkPlayerState]);

    useEffect(() => {
      if (transcriptUrlWithToken) {
        fetch(transcriptUrlWithToken)
          .then((response) => {
            if (response.ok) {
              setCaptionsAvailable(true);
              console.log('Transcript is available');
            } else {
              setCaptionsAvailable(false);
              console.log('Transcript is not available');
            }
          })
          .catch((error) => {
            console.error('Error checking transcript availability:', error);
            setCaptionsAvailable(false);
          });
      }
    }, [transcriptUrlWithToken]);

    // useEffect(() => {
    //   if (plyrInstanceRef.current) {
    //     plyrInstanceRef.current.on('ready', () => {
    //       console.log('Plyr is ready');
    //       // Reinitialize markers here if needed
    //       if (plyrInstanceRef.current.markers) {
    //         plyrInstanceRef.current.markers.destroy();
    //         plyrInstanceRef.current.markers.add(markers);
    //       }
    //     });
    //   }
    // }, [markers]);

    const handleVideoClick = useCallback(() => {
      // if (plyrInstanceRef.current) {
      //   if (plyrInstanceRef.current.paused) {
      //     plyrInstanceRef.current.play();
      //   } else {
      //     plyrInstanceRef.current.pause();
      //   }
      // }
    }, []);

    if (!token || !videoFileName) {
      console.error('SAS token or video filename is missing or null');
      return null;
    }

    return (
      <div className="video-container">
        <Plyr
          ref={(el) => {
            if (el) {
              plyrInstanceRef.current = el.plyr;
            }
            if (typeof ref === 'function') ref(el);
            else if (ref) ref.current = el;
          }}
          source={videoSource}
          options={plyrOptions}
        />
      </div>
    );
  }
);

export default VideoPlayer;
