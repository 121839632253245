import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSharedVideo } from '../api/beaverApi';
import VideoPlayer from './VideoPlayer.js';
import Transcript from './Transcript';
import '../styles/SharedVideo.css';
import { Download } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { logDownloadInteraction } from '../api/beaverApi';

const formatRecordingDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

const SharedVideo = () => {
  const { sharedVideoId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [videoNotFound, setVideoNotFound] = useState(false);
  const [expired, setExpired] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);
  const [waitTime, setWaitTime] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const playerStateRef = useRef({ currentTime: 0, isPlaying: false });
  console.log('fetching shared video', sharedVideoId);
  const fetchVideo = async (password = null) => {
    try {
      setIsLoading(true);
      const data = await fetchSharedVideo(sharedVideoId, password);
      setVideoData(data);
      setRequiresPassword(false);
      setError(null);
      setWaitTime(null);
      //   console.log('video data', data);
    } catch (err) {
      console.log('error fetching shared video', err);
      if (err.response?.status === 401 && err.response?.data?.requiresPassword) {
        setRequiresPassword(true);
      } else if (err.response?.status === 403) {
        setError('Invalid password. Please try again.');
        setRequiresPassword(true);
      } else if (err.response?.status === 404) {
        setError('Video not found');
        setVideoNotFound(true);
      } else if (err.response?.status === 410) {
        setError('This shared video has expired');
        setExpired(true);
      } else if (err.response?.status === 429) {
        setWaitTime(err.response.data.waitTime);
        setError(`Too many failed attempts. Please wait ${err.response.data.waitTime} seconds before trying again.`);
        setRequiresPassword(true);
      } else {
        setError('An error occurred while fetching the video');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideo();
  }, [sharedVideoId]);

  useEffect(() => {
    let timer;
    if (waitTime > 0) {
      setCountdown(waitTime);
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            setWaitTime(null);
            setError(null);
            return null;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [waitTime]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password) {
      fetchVideo(password);
    } else {
      setError('This requires a password');
    }
  };

  const handleDownload = () => {
    if (videoData.watermarkedVideo === false) {
      return;
    }

    const downloadUrl = `https://plixvideostorage.blob.core.windows.net/videos-whole/${videoData.FileName}?${videoData.sasToken}`;
    console.log('downloading video', downloadUrl);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = videoData.FileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    try {
      logDownloadInteraction(
        'download shared video',
        {
          sharedVideoId: videoData.id,
          deviceId: videoData.deviceId,
          fileName: videoData.FileName,
        },
        videoData.createdBy || videoData.userId,
        'External User',
        password
      );
    } catch (error) {
      console.error('Error logging download interaction:', error);
    }
  };
  const handleTranscriptClick = useCallback((timestamp) => {
    if (playerRef.current && playerRef.current.plyr) {
      playerRef.current.plyr.currentTime = timestamp;
    }
  }, []);

  const handlePlayerUpdate = useCallback(({ currentTime, isPlaying }) => {
    console.log('Time updated! ', { currentTime, isPlaying });
    playerStateRef.current = { currentTime, isPlaying };
  }, []);

  const getPlayerState = useCallback(() => playerStateRef.current, []);

  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }

  if (requiresPassword) {
    return (
      <div className="password-prompt-container">
        <div className="shared-logo-container">
          <img
            src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fi.postimg.cc%2FJ7FDKznn%2FGroup-32-1.png"
            alt="Plix Logo"
            className="shared-plix-logo"
          />
          {/* <span className="plix-text">PLIX</span> */}
        </div>
        <div className="password-prompt">
          <h2>This video is password protected</h2>
          <div className="password-input-container">
            <form onSubmit={handlePasswordSubmit}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                disabled={countdown > 0}
              />
              <button type="submit" disabled={countdown > 0 || isLoading}>
                View Video
              </button>
            </form>
          </div>
          {error && (
            <p className="error">
              {countdown ? `Too many failed attempts. Please wait ${countdown} seconds before trying again.` : error}
            </p>
          )}
        </div>
      </div>
    );
  }

  if (videoNotFound) {
    return (
      <div className="password-prompt-container">
        <div className="shared-logo-container">
          <img
            src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fi.postimg.cc%2FJ7FDKznn%2FGroup-32-1.png"
            alt="Plix Logo"
            className="shared-plix-logo"
          />
        </div>
        <div className="password-prompt not-found-prompt">
          <div className="not-found-icon">
            <i className="fas fa-unlink"></i>
          </div>
          <div className="not-found-content">
            <h1 className="not-found-title">Broken Link</h1>
            <p className="not-found-subtitle">Please check the link and try again</p>
          </div>
        </div>
      </div>
    );
  }

  if (expired) {
    return (
      <div className="password-prompt-container">
        <div className="shared-logo-container">
          <img
            src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fi.postimg.cc%2FJ7FDKznn%2FGroup-32-1.png"
            alt="Plix Logo"
            className="shared-plix-logo"
          />
        </div>
        <div className="password-prompt expired-prompt">
          <div className="expired-icon">
            <i className="fas fa-clock"></i>
          </div>
          <div className="expired-content">
            <h1 className="expired-title">This link has expired</h1>
            <p className="expired-subtitle">Please contact the owner</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }
  if (!videoData) {
    return null;
  }

  return (
    <>
      <div className="shared-header">
        <div className="shared-header-left">
          {/* <div className="logo-container"> */}
          <img
            src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fi.postimg.cc%2FJ7FDKznn%2FGroup-32-1.png"
            alt="Plix Logo"
            className="shared-plix-logo"
          />
          {/* <span className="plix-text">PLIX</span> */}
          {/* </div> */}
        </div>
      </div>
      <div className="shared-video">
        <div className="shared-video-display">
          <div className="shared-video-player-wrapper">
            <div className="shared-video-container">
              <VideoPlayer
                ref={playerRef}
                videoFileName={videoData.FileName}
                token={videoData.sasToken}
                markers={[]}
                initialTime={videoData.initialTime}
                transcriptUrl={videoData.TranscriptVTTPath}
                onPlayerUpdate={handlePlayerUpdate}
              />
            </div>
            <div className="shared-transcript-container">
              <Transcript
                transcriptUrl={`${videoData.TranscriptVTTPath}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`}
                getPlayerState={getPlayerState}
                onTranscriptClick={handleTranscriptClick}
                defaultShowTranscript={true}
                showTimeline={false}
              />
            </div>
          </div>
          <div className="shared-video-metadata">
            <div className="shared-metadata-header">
              <h2 className="filename">Recorded on {formatRecordingDate(videoData.TimeRecorded)}</h2>
              <div className="shared-metadata-actions">
                {videoData.isDownloadable && (
                  <Tooltip
                    title={
                      videoData.watermarkedVideo === false
                        ? 'Download will be available after watermarking'
                        : 'Download'
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: '12px',
                          borderRadius: '4px',
                          padding: '8px 12px',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#333',
                        },
                      },
                    }}
                  >
                    <div style={{ display: 'inline-block' }}>
                      <div
                        className={`shared-download-button ${videoData.watermarkedVideo === false ? 'disabled' : ''}`}
                        onClick={handleDownload}
                        style={{
                          cursor: videoData.watermarkedVideo === false ? 'not-allowed' : 'pointer',
                          opacity: videoData.watermarkedVideo === false ? 0.5 : 1,
                        }}
                      >
                        <Download />
                      </div>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="shared-metadata-content">
              <div className="shared-metadata-details">
                <div className="shared-metadata-item">
                  <span className="shared-metadata-label">Available until</span>
                  <span className="shared-metadata-value">{new Date(videoData.expirationDate).toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedVideo;
