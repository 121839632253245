import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React from 'react';

function QuestionComponent(props) {
  const { node } = props;
  const question = node.attrs.q || '';

  return (
    <NodeViewWrapper
      as="span"
      style={{
        backgroundColor: '#fff9c4',
        color: '#7c7c00',
        borderRadius: '3px',
        padding: '0 3px',
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'background-color 0.2s ease',
      }}
      className="question-node"
    >
      Q: {question}
    </NodeViewWrapper>
  );
}

export const Question = Node.create({
  name: 'question',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      q: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-question]',
        getAttrs: (element) => {
          return { q: element.getAttribute('data-q') || '' };
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-question': '',
        'data-q': node.attrs.q,
        style: [
          'background-color: #fff9c4',
          'color: #7c7c00',
          'cursor: pointer',
          'border-radius: 3px',
          'padding: 0 3px',
        ].join('; '),
      }),
      `Q: ${node.attrs.q}`,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(QuestionComponent);
  },
});
