import mixpanel from 'mixpanel-browser';
import { PLIX_OVERWATCH_ACCOUNT } from './utilsEvents';

export const TRACKING_EVENTS = {
  DASHBOARD: {
    VIEWED: 'Dashboard Viewed',
    SHIFT_CLICKED: 'Dashboard Shift Clicked',
    EVENT_CLICKED: 'Dashboard Timeline Event Clicked',
    MAP_CONTROL_CHANGED: 'Dashboard Map Control Changed',
    USER_FILTERED: 'Dashboard User Filtered',
    VIEW_CHANGED: 'Dashboard View Changed',
    TIME_PERIOD_CHANGED: 'Dashboard Time Period Changed',
    PERSONNEL_SELECTED: 'Dashboard Personnel Selected',
  },
  VIDEO_MANAGEMENT: {
    VIEWED: 'Video Management Viewed',
    VIDEO_SELECTED: 'Video Management Video Selected',
    VIDEO_SEARCHED: 'Video Management Video Searched',
    VIDEO_SHARED: 'Video Management Video Shared',
    VIDEO_ARCHIVED: 'Video Management Video Archived',
    VIEWED_ARCHIVED_VIDEOS: 'Video Management Viewed Archived Videos',
    DOWNLOAD_ARCHIVED_VIDEO: 'Video Management Download Archived Video',
    VIDEO_UNARCHIVED: 'Video Management Video Unarchived',
    VIDEO_PLAYED: 'Video Management Video Played',
  },
  REALTIME_MAP: {
    SESSION_START: 'RealTime Map Session Start',
    SESSION_END: 'RealTime Map Session End',
    SESSION_HEARTBEAT: 'RealTime Map Heartbeat',
  },
};

const trackEvent = (eventName, properties) => {
  // Skip tracking for PLIX_OVERWATCH_ACCOUNT
  console.log('mixpanel.get_distinct_id()', mixpanel.get_distinct_id());
  if (mixpanel.get_distinct_id() === PLIX_OVERWATCH_ACCOUNT) {
    return;
  }
  mixpanel.track(eventName, properties);
};

// RealTimeMap tracking functions
export const startMapTracking = (userId) => {
  if (userId === PLIX_OVERWATCH_ACCOUNT) {
    return null;
  }

  trackEvent(TRACKING_EVENTS.REALTIME_MAP.SESSION_START);

  // Start heartbeat
  const heartbeatInterval = setInterval(
    () => {
      if (userId === PLIX_OVERWATCH_ACCOUNT) {
        clearInterval(heartbeatInterval);
        return;
      }
      trackEvent(TRACKING_EVENTS.REALTIME_MAP.SESSION_HEARTBEAT);
    },
    5 * 60 * 1000
  ); // Every 5 minutes

  return heartbeatInterval;
};

export const endMapTracking = (heartbeatInterval) => {
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
  }
  trackEvent(TRACKING_EVENTS.REALTIME_MAP.SESSION_END);
};

// Dashboard tracking functions
export const trackShiftClick = (shiftId, deviceId, startTime) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.SHIFT_CLICKED, {
    shiftId,
    deviceId,
    startTime,
  });
};

export const trackEventClick = (shiftId, eventType, eventTime) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.EVENT_CLICKED, {
    shiftId,
    eventType,
    eventTime,
  });
};

export const trackMapControl = (shiftId, controlType, newValue) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.MAP_CONTROL_CHANGED, {
    shiftId,
    controlType,
    newValue,
  });
};

export const trackUserFilter = (selectedUsers, totalUsers) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.USER_FILTERED, {
    selectedUsers,
    totalUsers,
    filterCount: selectedUsers.length,
  });
};

export const trackViewChange = (newView, previousView) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.VIEW_CHANGED, {
    newView,
    previousView,
  });
};

export const trackTimePeriodChange = (newPeriod, previousPeriod) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.TIME_PERIOD_CHANGED, {
    newPeriod,
    previousPeriod,
    weeksBack: newPeriod.weeksBack,
  });
};

export const trackPersonnelSelection = (selectedIds, totalPersonnel) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.PERSONNEL_SELECTED, {
    selectedCount: selectedIds.length,
    totalPersonnel,
  });
};

export const trackDashboardView = (userId, userRole) => {
  trackEvent(TRACKING_EVENTS.DASHBOARD.VIEWED, {
    user_id: userId,
    user_role: userRole,
    timestamp: new Date().toISOString(),
  });
};

export const trackVideoManagementView = (userId, userRole) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIEWED, {
    user_id: userId,
    user_role: userRole,
    timestamp: new Date().toISOString(),
  });
};

export const trackVideoSelected = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_SELECTED, {
    video_id: videoId,
  });
};

export const trackVideoSearch = (searchTerm) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_SEARCHED, {
    search_term: searchTerm,
  });
};

export const trackVideoShared = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_SHARED, {
    video_id: videoId,
  });
};

export const trackVideoArchived = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_ARCHIVED, {
    video_id: videoId,
  });
};

export const trackDownloadArchivedVideo = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.DOWNLOAD_ARCHIVED_VIDEO, {
    video_id: videoId,
  });
};

export const trackVideoUnarchived = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_UNARCHIVED, {
    video_id: videoId,
  });
};

export const trackVideoPlayed = (videoId) => {
  trackEvent(TRACKING_EVENTS.VIDEO_MANAGEMENT.VIDEO_PLAYED, {
    video_id: videoId,
  });
};

export const trackPageView = (isAuthenticated) => {
  trackEvent('Page View', {
    authenticated: isAuthenticated,
    path: window.location.pathname,
  });
};
