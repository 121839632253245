import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/NewDashboard/DashboardControls/PersonnelSelector.css';
import { trackUserFilter } from '../../../utils/analytics';

const PersonnelSelector = ({ deviceAssignments, onPersonnelChange, selectedPersonnel }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePersonnelChange = (deviceId) => {
    const updatedPersonnel = selectedPersonnel.includes(deviceId)
      ? selectedPersonnel.filter((id) => id !== deviceId)
      : [...selectedPersonnel, deviceId];

    onPersonnelChange(updatedPersonnel);
    trackUserFilter(updatedPersonnel, Object.keys(deviceAssignments));
  };

  const handleSelectAll = () => {
    const allPersonnel = Object.keys(deviceAssignments);
    onPersonnelChange(allPersonnel);
    trackUserFilter(allPersonnel, allPersonnel);
  };

  const handleDeselectAll = () => {
    onPersonnelChange([]);
    trackUserFilter([], Object.keys(deviceAssignments));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="personnel-selector-container">
      <div className="personnel-selector-controls">
        <button
          className={`personnel-toggle-btn ${isDropdownOpen ? 'active' : ''}`}
          onClick={toggleDropdown}
          ref={buttonRef}
        >
          <FontAwesomeIcon icon={faFilter} /> Users
        </button>
        {isDropdownOpen && (
          <div className="personnel-dropdown" ref={dropdownRef}>
            <div className="dropdown-actions">
              <button onClick={handleSelectAll}>Select All</button>
              <button onClick={handleDeselectAll}>Deselect All</button>
            </div>
            {Object.entries(deviceAssignments).map(([deviceId, name]) => (
              <button
                key={deviceId}
                className={`dropdown-item ${selectedPersonnel.includes(deviceId) ? 'selected' : ''}`}
                onClick={() => handlePersonnelChange(deviceId)}
              >
                <span className="dropdown-item-check">
                  {selectedPersonnel.includes(deviceId) && <FontAwesomeIcon icon={faCheck} />}
                </span>
                <span className="dropdown-item-name">{name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonnelSelector;
