import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/ShiftExpanded.css';
import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';
import CustomizedTimeline from './Timeline/CustomizedTimeline';
import EventDetail from './EventDetail/EventDetail';
import EventInfo from './EventInfo/EventInfo';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faVideo, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagOutlined } from '@fortawesome/free-regular-svg-icons';
import { fetchLocationsForShift } from '../../../../../../api/beaverApi';
import { getDaysDifference } from '../../../../../../utils/shiftUtils';
import { formatDuration } from '../../../../../../utils/timeUtils';
// import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons';
import ErrorBoundary from '../../../../../ErrorBoundary';
import { Tooltip } from '@mui/material';
import { useRef, useCallback } from 'react';
// import TimelineMap from './TimelineMap';
import { formatDistanceToNow } from 'date-fns';
import axios from 'axios'; // Make sure to import axios
import { fetchAlertFromApi } from '../../../../../../api/beaverApi';
import { trackShiftClick } from '../../../../../../utils/analytics';

const ShiftExpanded = ({
  shift,
  batteryLevels,
  onCollapse,
  onFlagShift,
  timeRatio,
  deviceId,
  isOffline,
  offlineSince,
  formattedDuration,
  geofences,
  retentionPeriodDays,
  isOverwatch,
}) => {
  const { assignedTo, startTime, endTime, events, duration, inProgress, isFlagged } = shift;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [locations, setLocations] = useState([]);
  const daysDifference = endTime ? getDaysDifference(startTime, endTime) : 0;
  const eventTypes = {
    escalation: { color: '#ffa726', title: 'Escalation' },
    offline: { color: '#888', title: 'Deactivated' },
    start: { color: '#66bb6a', title: 'Shift Started' },
    end: { color: '#f44336', title: 'Shift Ended' },
    default: { color: '#29b6f6', title: 'Event' },
    recording_end: { color: '#29b6f6', title: 'Recording Ended' },
    recordingVideo: { color: '#29b6f6', title: 'Recording' },
    online: { color: '#888', title: 'Activated' },
    locationEnter: { color: '#ffd700', title: 'Site' },
  };

  const [selectedEventLocation, setSelectedEventLocation] = useState(null);

  const [markers, setMarkers] = useState([]);

  const handleEventSelect = (selectedEvent) => {
    setSelectedEvent(selectedEvent);

    // Find the location for the selected event
    if (locations.length > 0) {
      if (selectedEvent && selectedEvent.event) {
        const eventTime = new Date(selectedEvent.event.timestamp);
        const closestLocation = locations.reduce((prev, curr) => {
          const prevDiff = Math.abs(new Date(prev.time) - eventTime);
          const currDiff = Math.abs(new Date(curr.time) - eventTime);
          return currDiff < prevDiff ? curr : prev;
        });
        setSelectedEventLocation(closestLocation);
      } else {
        setSelectedEventLocation(locations[0]);
      }
    }
  };

  useEffect(() => {
    trackShiftClick(shift.id, deviceId, startTime);
  }, [shift.id, deviceId, startTime]);

  // console.log('geofences in expanded', geofences);
  useEffect(() => {
    const fetchOrCreateLocations = async () => {
      // test_id shift is only for demo purposes
      if (shift.id === 'test_id') {
        const fakeLocations = createFakeLocations(shift.startTime, shift.endTime);
        setLocations(fakeLocations);
      } else if (shift.id === '805a9f8f-8d10-488a-8a94-a87af36023ac_test') {
        const startTime = new Date(shift.startTime);
        const endTime = new Date(startTime.getTime() + 30 * 60000); // 30 minutes in milliseconds
        const fakeLocations = createFakeLocations_alice(startTime, endTime.toISOString());
        setLocations(fakeLocations);
      } else {
        const endTimeToUse = shift.inProgress ? new Date().toISOString() : shift.endTime;
        const fetchedLocations = await fetchLocationsForShift(deviceId, shift.startTime, endTimeToUse);
        setLocations(fetchedLocations);
      }
    };

    fetchOrCreateLocations();
  }, [deviceId, shift.startTime, shift.endTime, shift.inProgress]);

  useEffect(() => {
    if (shift.events && shift.events.length > 0) {
      setSelectedEvent({ index: 0, event: shift.events[0] });
    }
  }, [shift.events]);

  useEffect(() => {
    if (
      selectedEvent &&
      selectedEvent.event &&
      selectedEvent.event.type === 'escalation' &&
      selectedEvent.event.alertId
    ) {
      setAlertData(null);
      fetchAlertFromApi(selectedEvent.event.alertId)
        .then((data) => {
          setAlertData(data);
          setShowVideo(true);
        })
        .catch((err) => {
          console.error('Failed to fetch alert data:', err);
        });
    } else {
      setAlertData(null);
      setShowVideo(false);
    }
  }, [selectedEvent]);
  // console.log('selectedEvent', selectedEvent);
  // console.log('data from expanded', alertData);
  const escalationCount = events.filter((event) => event.type === 'escalation').length;

  const videoPlayerRef = useRef(null);
  const playerStateRef = useRef({ currentTime: 0, isPlaying: false });

  const handlePlayerUpdate = useCallback(({ currentTime, isPlaying }) => {
    console.log('Time updated! ', { currentTime, isPlaying });
    playerStateRef.current = { currentTime, isPlaying };
  }, []);

  const handleTranscriptClick = useCallback((timestamp) => {
    if (videoPlayerRef.current && videoPlayerRef.current.plyr) {
      videoPlayerRef.current.plyr.currentTime = timestamp;
    }
  }, []);

  const getPlayerState = useCallback(() => playerStateRef.current, []);

  const handleFlagShift = (e) => {
    e.stopPropagation(); // Stop the event from propagating to parent elements
    const currentFlagStatus = Boolean(shift.isFlagged);
    const newFlagStatus = !currentFlagStatus;
    console.log(`Toggling flag for shift ${shift.id} from ${currentFlagStatus} to ${newFlagStatus}`);
    onFlagShift(shift.id, newFlagStatus);
  };

  const onlineTime = formatDuration(shift.calculatedOnlineTime * 1000);
  const offlineTime = formatDuration(shift.calculatedOfflineTime * 1000);

  const timeRatioTooltip = (
    <div className="custom-tooltip-time">
      <div className="tooltip-row">
        <span className="tooltip-dot online"></span>
        <span className="tooltip-label">Active</span>
        <span className="tooltip-value">{onlineTime}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-dot offline"></span>
        <span className="tooltip-label">Inactive</span>
        <span className="tooltip-value">{offlineTime}</span>
      </div>
    </div>
  );

  const offlineSinceText = offlineSince
    ? `Device was in shift but lost connection ${formatDistanceToNow(new Date(offlineSince))} ago`
    : 'Device was in shift but lost connection';

  const renderRecordingStatus = () => {
    if (shift.isRecording) {
      return (
        <Tooltip title="Recording in progress." placement="top">
          <span className="recording-status">
            <FontAwesomeIcon icon={faVideo} className="recording-icon blinking" />
            <span className="recording-text">Recording</span>
          </span>
        </Tooltip>
      );
    } else if (shift.totalRecordingDuration > 0) {
      return (
        <span className="recording-duration-badge">
          <FontAwesomeIcon icon={faVideo} className="recording-icon" />
          <span className="recording-duration">{formatDuration(shift.totalRecordingDuration)}</span>
        </span>
      );
    }
    return null;
  };

  const handleAssignedToClick = (e) => {
    e.stopPropagation(); // Prevent the click from collapsing the expanded view
    if (isOverwatch) {
      const grafanaUrl = `http://nginx-test.tail24705.ts.net:3000/d/adoh9r7dkkl4wf/device-status?orgId=1&var-device1=${deviceId}&from=now-7d&to=now`;
      window.open(grafanaUrl, '_blank');
    }
  };

  const renderAssignedTo = () => {
    const content = (
      <div
        className="shift-expanded-personnel"
        onClick={handleAssignedToClick}
        style={isOverwatch ? { cursor: 'pointer' } : {}}
      >
        {assignedTo === '' ? deviceId : assignedTo}
      </div>
    );

    if (isOverwatch) {
      return (
        <Tooltip title={deviceId} placement="top-start" classes={{ tooltip: 'custom-tooltip-wrapper' }}>
          {content}
        </Tooltip>
      );
    }
    return content;
  };

  return (
    <div className="shift-expanded-container">
      <div className="shift-expanded-header" onClick={onCollapse}>
        <div className="shift-expanded-left">
          {renderAssignedTo()}
          {/* <div className="shift-expanded-date">
            <span className="date-badge">{new Date(startTime).toLocaleDateString()}</span>
          </div> */}
          <div className="shift-expanded-time">
            <div className="start-time-container">
              <span className="time-badge">
                {new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
              <span className="online-time-decimal">{new Date(startTime).toLocaleDateString()}</span>
            </div>
            <div className="duration-container">
              <span className="online-time-decimal">{formattedDuration}</span>
              <hr className="duration-divider"></hr>
            </div>
            <div className="end-time-container">
              {inProgress ? (
                <>
                  <span
                    className={`time-badge ${isOffline ? 'offline' : 'in-progress'}`}
                    data-offline-since={offlineSinceText}
                  >
                    {isOffline ? 'Connection Lost' : 'In Progress'}
                  </span>
                  <span className="online-time-decimal">{new Date().toLocaleDateString()}</span>
                </>
              ) : (
                <>
                  <span className="time-badge">
                    {new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    {daysDifference > 0 && <sup>+{daysDifference}</sup>}
                  </span>
                  <span className="online-time-decimal">{new Date(endTime).toLocaleDateString()}</span>
                </>
              )}
            </div>
          </div>
          {!inProgress && (
            <>
              {/* <span className="duration-badge">{formatDuration(duration)}</span> */}
              <div className="online-time-container">
                <span className="online-time-decimal">{onlineTime} active</span>
                <Tooltip title={timeRatioTooltip} classes={{ tooltip: 'custom-tooltip-wrapper' }}>
                  <div className="time-ratio-bar">
                    <div
                      className="online-time"
                      style={{
                        width: `${timeRatio.onlineRatio}%`,
                      }}
                    ></div>
                    {shift.calculatedOfflineTime > 0 && (
                      <div
                        className="offline-time"
                        style={{
                          width: `${timeRatio.offlineRatio}%`,
                        }}
                      ></div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </>
          )}
          <div className="recording-duration-container">{renderRecordingStatus()}</div>
        </div>
        <div className="shift-expanded-right">
          <div className="shift-expanded-escalations">
            {escalationCount > 0 && (
              <span className="escalation-badge">
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="escalation-icon" />
                  <span className="escalation-count">{escalationCount}</span>
                </>
              </span>
            )}
          </div>
        </div>
        <div
          className="shift-flags"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleFlagShift}
        >
          {shift.isFlagged ? (
            <FontAwesomeIcon icon={faFlagSolid} className="flag-icon filled" onClick={handleFlagShift} />
          ) : (
            <FontAwesomeIcon
              icon={isHovered ? faFlagSolid : faFlagOutlined}
              className={`flag-icon ${isHovered ? 'filled' : 'outlined'}`}
              onClick={handleFlagShift}
            />
          )}
        </div>
        <button className="shift-expanded-collapse">-</button>
      </div>
      <div className="shift-expanded-content">
        <CustomizedTimeline
          events={shift.events}
          onEventSelect={handleEventSelect}
          deviceId={deviceId}
          batteryLevels={batteryLevels}
          eventTypes={eventTypes}
          isShiftInProgress={inProgress}
          geofences={geofences}
          alertData={alertData}
        />
        {selectedEvent && (
          <>
            <ErrorBoundary>
              <EventDetail
                showVideo={showVideo}
                event={selectedEvent.event}
                events={shift.events}
                onShowVideo={setShowVideo}
                alertData={alertData}
                locations={locations}
                videoPlayerRef={videoPlayerRef}
                onPlayerUpdate={handlePlayerUpdate}
                geofences={geofences}
                focusLocation={selectedEventLocation}
                retentionPeriodDays={retentionPeriodDays}
                markers={markers}
              />
            </ErrorBoundary>
            <EventInfo
              showVideo={showVideo}
              selectedEvent={selectedEvent}
              alertData={alertData}
              assignedTo={assignedTo}
              allEvents={shift.events}
              getPlayerState={getPlayerState}
              onTranscriptClick={handleTranscriptClick}
              eventTypes={eventTypes}
              shiftEndTime={endTime}
              isShiftInProgress={inProgress}
              locations={locations}
              setMarkers={setMarkers}
            />
          </>
        )}
      </div>
    </div>
  );
};

// for demo purposes
const createFakeLocations = (startTime, endTime) => {
  const start = new Date('2024-07-30T04:15:05.51228+00:00');
  const end = new Date('2024-07-30T11:42:36.221062+00:00');
  const fakeLocations = [];
  const baseLatitude = 38.5809;
  const baseLongitude = -121.496;

  for (let time = start; time <= end; time.setMinutes(time.getMinutes() + 10)) {
    fakeLocations.push({
      time: time.toISOString(),
      latitude: baseLatitude + (Math.random() - 0.005) * 0.0001,
      longitude: baseLongitude + (Math.random() - 0.005) * 0.0001,
    });
  }

  return fakeLocations;
};

const createFakeLocations_alice = (startTime, endTime, shift) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const fakeLocations = [];

  // Define all locations, including intermediate ones
  const locations = [
    { latitude: 28.4378032013227, longitude: -81.46842199953937 },
    { latitude: 28.435167818407763, longitude: -81.46720298875319 },
    { latitude: 28.43410465046346, longitude: -81.46597642432204 },
    { latitude: 28.433171961397992, longitude: -81.46314471034884 },
    { latitude: 28.432917431376087, longitude: -81.45997069185358 },
    { latitude: 28.431899634443948, longitude: -81.45932189505902 },
    { latitude: 28.43131418896484, longitude: -81.45954250795917 },
    { latitude: 28.431283494020242, longitude: -81.46045437086462 },
    { latitude: 28.4281, longitude: -81.4614 },
  ];

  // Calculate the total duration and number of steps
  const totalDuration = end - start;
  const totalSteps = locations.length * 20; // 20 steps between each location pair
  const timePerStep = totalDuration / totalSteps;

  for (let i = 0; i < totalSteps; i++) {
    const overallProgress = i / totalSteps;
    const currentTime = new Date(start.getTime() + i * timePerStep);

    // Determine which segment we're in
    const segmentIndex = Math.floor(overallProgress * (locations.length - 1));
    const segmentProgress = (overallProgress * (locations.length - 1)) % 1;

    const startLoc = locations[segmentIndex];
    const endLoc = locations[segmentIndex + 1];

    // Interpolate between the two locations in the current segment
    const latitude = startLoc.latitude + (endLoc.latitude - startLoc.latitude) * segmentProgress;
    const longitude = startLoc.longitude + (endLoc.longitude - startLoc.longitude) * segmentProgress;

    // Add some randomness to make it look more natural
    const jitter = 0.00001; // Reduced jitter for more precise paths
    const randomLat = (Math.random() - 0.5) * jitter;
    const randomLon = (Math.random() - 0.5) * jitter;

    fakeLocations.push({
      time: currentTime.toISOString(),
      latitude: latitude + randomLat,
      longitude: longitude + randomLon,
    });
  }

  return fakeLocations;
};

export default ShiftExpanded;
