import React, { useState, useEffect, useMemo } from 'react';
import VideoPlayer from '../../../../../../VideoPlayer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/VideoView.css';
import { fetchAlertFromApi, fetchVideoByFileName } from '../../../../../../../api/beaverApi';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const fetchAlertWithRetry = async (alertId, retries = 0) => {
  try {
    return await fetchAlertFromApi(alertId);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch for alert ${alertId}, attempt ${retries + 1}`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchAlertWithRetry(alertId, retries + 1);
    }
    throw new Error(`Failed to fetch alert data after ${MAX_RETRIES} attempts: ${err.message}`);
  }
};

const VideoView = React.memo(
  ({ event, videoPlayerRef, onPlayerUpdate, retentionPeriodDays, markers, isMobile, currentTime }) => {
    const [videoData, setVideoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [videoTime, setVideoTime] = useState(0);
    const [initialTime, setInitialTime] = useState(0);

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          if (event.type === 'escalation' && event.alertId) {
            const data = await fetchAlertWithRetry(event.alertId);
            console.log('data: ', data, ' event: ', event);
            const video = await fetchVideoByFileName(data.VideoFileName);
            console.log('video: ', video);
            if (checkRetentionPeriod(data.Timestamp)) {
              setVideoData({
                ...data,
                VideoFileName: data.VideoFileName,
                IsIngesting: video && video.generatedEmbeddings && !video.TranscriptVTTPath,
              });
            } else {
              setError('Video past retention period.');
            }
          } else if (event.type === 'recordingVideo') {
            if (checkRetentionPeriod(event.timestamp)) {
              console.log('event: ', event);
              setVideoData({
                VideoFileName: event.fileName,
                BlobPath: event.blobPath,
                Duration: event.duration,
                TimeRecorded: event.timestamp,
                IsIngesting: event && event.generatedEmbeddings && !event.TranscriptVTTPath,
              });
            } else {
              setError('Video past retention period.');
            }
          }
        } catch (err) {
          setError('Failed to fetch video data: ' + err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [event, retentionPeriodDays]);

    const checkRetentionPeriod = (timestamp) => {
      if (!retentionPeriodDays || !timestamp) return true;
      const recordingDate = new Date(timestamp);
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - recordingDate.getTime();
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      return daysDiff <= retentionPeriodDays;
    };

    const videoPlayerKey = useMemo(() => event.alertId || event.fileName, [event.alertId, event.fileName]);

    useEffect(() => {
      setInitialTime(videoTime);
    }, [event]);

    // useEffect(() => {
    //   if (videoPlayerRef.current?.plyr && currentTime !== undefined) {
    //     videoPlayerRef.current.plyr.currentTime = currentTime;
    //   }
    // }, [currentTime]);

    const renderVideoContent = () => {
      if (loading) {
        return (
          <div className="video-placeholder">
            <AccessTimeIcon className="placeholder-icon" />
            <p>Loading video...</p>
          </div>
        );
      }

      if (error) {
        return (
          <div className="video-placeholder">
            <ErrorOutlineIcon className="placeholder-icon" />
            <p>{error}</p>
          </div>
        );
      }

      if (videoData && videoData.IsIngesting) {
        return (
          <div className="video-placeholder">
            <AccessTimeIcon className="placeholder-icon" />
            <p>Video currently processing</p>
          </div>
        );
      }

      if (videoData && videoData.VideoFileName && !videoData.isPermanentlyDeleted) {
        return (
          <VideoPlayer
            videoFileName={videoData.VideoFileName}
            markers={markers}
            token={process.env.REACT_APP_BLOB_SAS_TOKEN}
            ref={videoPlayerRef}
            onPlayerUpdate={onPlayerUpdate}
            preStart={10}
            initialTime={initialTime}
            key={videoPlayerKey}
            isMobile={isMobile}
          />
        );
      }

      return (
        <div className="video-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Video currently unavailable</p>
        </div>
      );
    };

    if (isMobile) {
      return renderVideoContent();
    }

    return (
      <div className={`event-detail-video-view-container ${isMobile ? 'mobile' : ''}`}>
        <div className="event-detail-video-player-container">{renderVideoContent()}</div>
      </div>
    );
  }
);

export default VideoView;
