import React from 'react';
import ReportCard from './ReportCard';
import { format, isToday, isYesterday, isValid } from 'date-fns';
import '../styles/Reports/ReportCardList.css';

const ReportDateSeparator = ({ date }) => {
  const formatDate = (date) => {
    if (!isValid(date)) return 'Invalid Date';
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, 'EEEE, MMMM do');
  };

  return (
    <div className="report-date-separator">
      <div className="report-date-separator-line"></div>
      <div className="report-date-separator-bubble">{formatDate(date)}</div>
    </div>
  );
};

const ReportCardList = ({ reports, onReportClick, onDeleteReport, isMobile }) => {
  const sortedReports = reports.sort((a, b) => {
    return new Date(b.lastUpdated) - new Date(a.lastUpdated);
  });

  const reportsByDate = sortedReports.reduce((acc, report) => {
    const date = new Date(report.lastUpdated).toDateString();
    if (!acc[date]) acc[date] = [];
    acc[date].push(report);
    return acc;
  }, {});

  return (
    <div className="reports-list-container">
      <div className="reports-list">
        {Object.entries(reportsByDate).map(([date, dateReports]) => (
          <div key={date} className="reports-date-group">
            <ReportDateSeparator date={new Date(date)} />
            {dateReports.map((report) => (
              <ReportCard
                key={report.id || report.reportId}
                report={report}
                onClick={() => onReportClick(report)}
                onDelete={onDeleteReport}
                isMobile={isMobile}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportCardList;
