import React, { useState, useEffect } from 'react';
import { cosmosClient } from '../utils/eventstorage';
import '../styles/DeviceManagement.css';
import Modal from 'react-modal';
import axios from 'axios';
import { updateDeviceTag, triggerConfigUpdate } from '../api/beaverApi';

const getModeSettings = (mode) => {
  const modeSettings = {
    normal: {
      deviceMode: 'normal',
      screenDim: true,
      recBeep: false,
      TTSEnabled: true,
      hapticEnabled: true,
    },
    stealth: {
      deviceMode: 'stealth',
      screenDim: true,
      recBeep: false,
      TTSEnabled: false,
      hapticEnabled: true,
    },
    deterrent: {
      deviceMode: 'deterrent',
      screenDim: false,
      recBeep: true,
      TTSEnabled: true,
      hapticEnabled: true,
    },
  };
  return modeSettings[mode] || modeSettings.normal;
};

const DeviceManagement = () => {
  const [unassignedDevices, setUnassignedDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [userId, setUserId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgExists, setOrgExists] = useState(false);
  const [showOrgNameModal, setShowOrgNameModal] = useState(false);
  const [isUserIdValid, setIsUserIdValid] = useState(false);
  const [assignedDevices, setAssignedDevices] = useState([]);
  const [selectedAssignedDevices, setSelectedAssignedDevices] = useState([]);
  const [reassignUserId, setReassignUserId] = useState('');
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [isAssignedDevicesExpanded, setIsAssignedDevicesExpanded] = useState(false);
  const [orgMap, setOrgMap] = useState({});
  const [showShiftConfirmModal, setShowShiftConfirmModal] = useState(false);
  const [shiftAction, setShiftAction] = useState(''); // 'start' or 'stop'

  useEffect(() => {
    fetchUnassignedDevices();
    fetchAssignedDevices();
  }, []);

  const fetchUnassignedDevices = async () => {
    try {
      const devicesContainer = cosmosClient.database('Devices').container('Devices');
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE NOT IS_DEFINED(c.userId)',
        })
        .fetchAll();
      console.log('Fetched unassigned devices:', devices);
      setUnassignedDevices(devices);
    } catch (error) {
      console.error('Failed to fetch unassigned devices:', error);
    }
  };

  const fetchOrgConfig = async (userId) => {
    try {
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const querySpec = {
        query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
        parameters: [{ name: '@userId', value: userId }],
      };
      const { resources: configs } = await orgContainer.items.query(querySpec).fetchAll();
      if (configs.length > 0) {
        return configs[0]; // This should include the 'id' field
      }
      return null;
    } catch (error) {
      console.error('Failed to fetch organization config:', error);
      return null;
    }
  };

  const createNewOrg = async () => {
    if (!userId || !orgName) {
      alert('Please enter both User ID and Organization Name');
      return;
    }

    try {
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const newOrg = {
        orgName: orgName,
        orgUserId: userId,
        retentionPeriodDays: 30,
        orgCreationTimestamp: new Date().toISOString(), // Set the creation timestamp to the current date and time in UTC time and ISO format
        recordingPolicy: 'User',
        liveViewPolicy: 'During Shift',
        rtmpUrl: '',
        assingedTo: '',
        deviceIds: [],
      };

      const { resource: createdOrg } = await orgContainer.items.create(newOrg, { partitionKey: orgName });
      console.log('Created new org:', createdOrg);
      setOrgExists(true);
      alert('New organization created successfully!');
      return createdOrg;
    } catch (error) {
      console.error('Failed to create new organization:', error);
      alert('Failed to create new organization. Please try again.');
      return null;
    }
  };

  const handleDeviceSelection = (deviceId) => {
    setSelectedDevices((prev) =>
      prev.includes(deviceId) ? prev.filter((id) => id !== deviceId) : [...prev, deviceId]
    );
  };

  const assignDevices = async () => {
    if (!userId || !isUserIdValid) {
      alert('Please enter a valid user ID');
      return;
    }

    try {
      let orgConfig = await fetchOrgConfig(userId);
      if (!orgConfig) {
        alert('Organization not found. Please create one first.');
        return;
      }

      const devicesContainer = cosmosClient.database('Devices').container('Devices');
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      // Update devices
      for (const deviceId of selectedDevices) {
        console.log('Updating device:', deviceId);
        try {
          const querySpec = {
            query: 'SELECT * FROM c WHERE c.deviceId = @deviceId',
            parameters: [{ name: '@deviceId', value: deviceId }],
          };
          const { resources: devices } = await devicesContainer.items.query(querySpec).fetchAll();

          if (devices.length === 0) {
            throw new Error(`Device ${deviceId} not found`);
          }

          const device = devices[0];
          console.log('Existing device:', device);

          // Update device in Cosmos DB
          const updatedDevice = {
            ...device,
            userId,
            recordingPolicy: orgConfig.recordingPolicy ? orgConfig.recordingPolicy : 'User',
            liveViewPolicy: orgConfig.liveViewPolicy,
            activationKeyword: orgConfig.activationKeyword,
            stopPhrase: orgConfig.stopPhrase,
            notificationPhoneNumber: orgConfig.notificationPhoneNumber,
            buttonType: orgConfig.buttonType ? orgConfig.buttonType : 'DOUBLE_TAP',
            escalationMode: orgConfig.escalationMode ? orgConfig.escalationMode : 'on',
            deviceMode: getModeSettings(orgConfig.deviceMode).deviceMode,
            screenDim: getModeSettings(orgConfig.deviceMode).screenDim,
            recBeep: getModeSettings(orgConfig.deviceMode).recBeep,
            TTSEnabled: getModeSettings(orgConfig.deviceMode).TTSEnabled,
            hapticEnabled: getModeSettings(orgConfig.deviceMode).hapticEnabled,
          };
          console.log('Updated device object:', updatedDevice);

          await devicesContainer.items.upsert(updatedDevice);

          // Fire and forget device tag update and config update
          updateDeviceTag(deviceId, orgConfig.orgName).catch((error) =>
            console.error(`Error updating device tag for ${deviceId}:`, error)
          );

          triggerConfigUpdate(deviceId).catch((error) =>
            console.error(`Error triggering config update for ${deviceId}:`, error)
          );
        } catch (deviceError) {
          console.error(`Error updating device ${deviceId}:`, deviceError);
          throw deviceError;
        }
      }

      // Update organization
      const updatedOrgConfig = {
        ...orgConfig,
        deviceIds: [...new Set([...(orgConfig.deviceIds || []), ...selectedDevices])],
      };
      console.log('Updated org config:', updatedOrgConfig);

      await orgContainer.item(orgConfig.id, orgConfig.orgName).replace(updatedOrgConfig);

      alert('Devices assigned successfully!');
      await fetchUnassignedDevices();
      setSelectedDevices([]);
    } catch (error) {
      console.error('Failed to assign devices:', error);
      alert('Failed to assign devices. Please try again.');
    }
  };

  const handleCreateOrg = async () => {
    const createdOrg = await createNewOrg();
    if (createdOrg) {
      setShowOrgNameModal(false);
    }
  };

  const checkUserId = async () => {
    if (!userId) {
      alert('Please enter a User ID');
      return;
    }

    const orgConfig = await fetchOrgConfig(userId);
    if (orgConfig) {
      setIsUserIdValid(true);
      setOrgName(orgConfig.orgName);
      setShowOrgNameModal(false);
    } else {
      setIsUserIdValid(false);
      setOrgName('');
      setShowOrgNameModal(true);
    }
  };

  const fetchAssignedDevices = async () => {
    try {
      const devicesContainer = cosmosClient.database('Devices').container('Devices');
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE IS_DEFINED(c.userId)',
        })
        .fetchAll();
      console.log('Fetched assigned devices:', devices);
      setAssignedDevices(devices);

      // Fetch organization names for all unique userIds
      const uniqueUserIds = [...new Set(devices.map((device) => device.userId))];
      console.log('Unique user IDs:', uniqueUserIds);
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const orgPromises = uniqueUserIds.map(async (userId) => {
        const { resources: orgs } = await orgContainer.items
          .query({
            query: 'SELECT c.orgName, c.orgUserId FROM c WHERE c.orgUserId = @userId',
            parameters: [{ name: '@userId', value: userId }],
          })
          .fetchAll();
        console.log(`Org for userId ${userId}:`, orgs);
        return orgs.length > 0 ? orgs[0] : { orgUserId: userId, orgName: 'Unknown' };
      });
      const orgs = await Promise.all(orgPromises);
      console.log('Orgs:', orgs);
      const newOrgMap = Object.fromEntries(orgs.map((org) => [org.orgUserId, org.orgName]));
      console.log('New org map:', newOrgMap);
      setOrgMap(newOrgMap);
    } catch (error) {
      console.error('Failed to fetch assigned devices:', error);
    }
  };

  const handleAssignedDeviceSelection = (deviceId) => {
    setSelectedAssignedDevices((prev) =>
      prev.includes(deviceId) ? prev.filter((id) => id !== deviceId) : [...prev, deviceId]
    );
  };

  const openReassignModal = () => {
    if (selectedAssignedDevices.length === 0) {
      alert('Please select devices to reassign');
      return;
    }
    setShowReassignModal(true);
  };

  const reassignDevices = async () => {
    if (!reassignUserId) {
      alert('Please enter a User ID to reassign devices');
      return;
    }

    try {
      let targetOrgConfig = await fetchOrgConfig(reassignUserId);
      if (!targetOrgConfig) {
        const createOrg = window.confirm('Organization not found. Do you want to create a new one?');
        if (createOrg) {
          targetOrgConfig = await createNewOrg(reassignUserId);
        } else {
          return;
        }
      }

      const devicesContainer = cosmosClient.database('Devices').container('Devices');
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      // Update devices
      for (const deviceId of selectedAssignedDevices) {
        const device = assignedDevices.find((d) => d.deviceId === deviceId);
        if (!device) continue;

        const updatedDevice = {
          ...device,
          userId: reassignUserId,
          recordingPolicy: targetOrgConfig.recordingPolicy ? targetOrgConfig.recordingPolicy : 'User',
          liveViewPolicy: targetOrgConfig.liveViewPolicy,
          activationKeyword: targetOrgConfig.activationKeyword,
          stopPhrase: targetOrgConfig.stopPhrase,
          notificationPhoneNumber: targetOrgConfig.notificationPhoneNumber,
          buttonType: targetOrgConfig.buttonType ? targetOrgConfig.buttonType : 'DOUBLE_TAP',
          escalationMode: targetOrgConfig.escalationMode ? targetOrgConfig.escalationMode : 'on',
          deviceMode: getModeSettings(targetOrgConfig.deviceMode).deviceMode,
          screenDim: getModeSettings(targetOrgConfig.deviceMode).screenDim,
          recBeep: getModeSettings(targetOrgConfig.deviceMode).recBeep,
          TTSEnabled: getModeSettings(targetOrgConfig.deviceMode).TTSEnabled,
          hapticEnabled: getModeSettings(targetOrgConfig.deviceMode).hapticEnabled,
        };

        // Update device in Cosmos DB
        await devicesContainer.items.upsert(updatedDevice);

        // Fire and forget device tag update and config update
        updateDeviceTag(deviceId, targetOrgConfig.orgName).catch((error) =>
          console.error(`Error updating device tag for ${deviceId}:`, error)
        );

        triggerConfigUpdate(deviceId).catch((error) =>
          console.error(`Error triggering config update for ${deviceId}:`, error)
        );

        // Fire and forget device tag update and config update
        updateDeviceTag(deviceId, targetOrgConfig.orgName).catch((error) =>
          console.error(`Error updating device tag for ${deviceId}:`, error)
        );

        triggerConfigUpdate(deviceId).catch((error) =>
          console.error(`Error triggering config update for ${deviceId}:`, error)
        );

        // Remove device from old org
        if (device.userId) {
          const oldOrgConfig = await fetchOrgConfig(device.userId);
          if (oldOrgConfig) {
            const updatedOldOrgConfig = {
              ...oldOrgConfig,
              deviceIds: oldOrgConfig.deviceIds.filter((id) => id !== deviceId),
            };
            await orgContainer.item(oldOrgConfig.id, oldOrgConfig.orgName).replace(updatedOldOrgConfig);
          }
        }
      }

      // Update target org
      const updatedTargetOrgConfig = {
        ...targetOrgConfig,
        deviceIds: [...new Set([...targetOrgConfig.deviceIds, ...selectedAssignedDevices])],
      };
      await orgContainer.item(targetOrgConfig.id, targetOrgConfig.orgName).replace(updatedTargetOrgConfig);

      alert('Devices reassigned successfully!');
      setShowReassignModal(false);
      setReassignUserId('');
      setSelectedAssignedDevices([]);
      await fetchAssignedDevices();
      await fetchUnassignedDevices();
    } catch (error) {
      console.error('Failed to reassign devices:', error);
      alert('Failed to reassign devices. Please try again.');
    }
  };

  const toggleAssignedDevices = () => {
    setIsAssignedDevicesExpanded(!isAssignedDevicesExpanded);
  };

  const handleShiftAction = async (action) => {
    setShiftAction(action);
    setShowShiftConfirmModal(true);
  };

  const executeShiftAction = async () => {
    const actionFn = shiftAction === 'start' ? startAllShifts : stopAllShifts;
    await actionFn();
    setShowShiftConfirmModal(false);
  };

  const startAllShifts = async () => {
    if (!userId || !isUserIdValid) {
      alert('Please select a valid organization first');
      return;
    }

    try {
      const orgConfig = await fetchOrgConfig(userId);
      if (!orgConfig || !orgConfig.deviceIds || orgConfig.deviceIds.length === 0) {
        alert('No devices found in this organization');
        return;
      }

      const now = new Date().getTime();
      const results = await Promise.allSettled(
        orgConfig.deviceIds.map(async (deviceId) => {
          try {
            await axios.post(`https://utils.plix.ai/v1/startshift`, { deviceId });
            return deviceId;
          } catch (error) {
            console.error(`Failed to start shift for device ${deviceId}:`, error);
            throw error;
          }
        })
      );

      const succeeded = results.filter((r) => r.status === 'fulfilled').length;
      const failed = results.filter((r) => r.status === 'rejected').length;
      alert(`Started shifts for ${succeeded} devices. ${failed} devices failed.`);
    } catch (error) {
      console.error('Failed to start shifts:', error);
      alert('Failed to start shifts. Please try again.');
    }
  };

  const stopAllShifts = async () => {
    if (!userId || !isUserIdValid) {
      alert('Please select a valid organization first');
      return;
    }

    try {
      const orgConfig = await fetchOrgConfig(userId);
      if (!orgConfig || !orgConfig.deviceIds || orgConfig.deviceIds.length === 0) {
        alert('No devices found in this organization');
        return;
      }

      const results = await Promise.allSettled(
        orgConfig.deviceIds.map(async (deviceId) => {
          try {
            await axios.post(`https://utils.plix.ai/v1/stopshift`, { deviceId });
            return deviceId;
          } catch (error) {
            console.error(`Failed to stop shift for device ${deviceId}:`, error);
            throw error;
          }
        })
      );

      const succeeded = results.filter((r) => r.status === 'fulfilled').length;
      const failed = results.filter((r) => r.status === 'rejected').length;
      alert(`Stopped shifts for ${succeeded} devices. ${failed} devices failed.`);
    } catch (error) {
      console.error('Failed to stop shifts:', error);
      alert('Failed to stop shifts. Please try again.');
    }
  };

  return (
    <div className="device-management">
      <h1>Device Management</h1>
      <div className="user-input">
        <label htmlFor="userId">User ID:</label>
        <input
          type="text"
          id="userId"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Enter User ID"
        />
        <button
          className="device-management-btn"
          style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
          onClick={checkUserId}
        >
          Check User ID
        </button>
        {isUserIdValid && (
          <span className="valid-user-id">
            ✅ Valid User ID
            {orgName && <span className="org-name"> - Organization: {orgName}</span>}
          </span>
        )}
      </div>
      <div className="device-list">
        <h2>Unassigned Devices</h2>
        {unassignedDevices.map((device) => (
          <div key={device.deviceId} className="device-item">
            <input
              type="checkbox"
              checked={selectedDevices.includes(device.deviceId)}
              onChange={() => handleDeviceSelection(device.deviceId)}
            />
            <span>{device.deviceId}</span>
          </div>
        ))}
      </div>
      <button
        className="device-management-btn"
        style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
        onClick={assignDevices}
        disabled={selectedDevices.length === 0 || !isUserIdValid}
      >
        Assign Selected Devices
      </button>

      <Modal
        isOpen={showOrgNameModal}
        onRequestClose={() => setShowOrgNameModal(false)}
        contentLabel="Enter Organization Name"
      >
        <h2>New Organization</h2>
        <p>No organization found for this User ID. Please enter a new organization name:</p>
        <input
          type="text"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
          placeholder="Enter Organization Name"
        />
        <button
          className="device-management-btn"
          style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
          onClick={handleCreateOrg}
        >
          Create Organization
        </button>
        <button
          className="device-management-btn"
          style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
          onClick={() => setShowOrgNameModal(false)}
        >
          Cancel
        </button>
      </Modal>

      <div className="assigned-devices-section">
        <h2 onClick={toggleAssignedDevices} className="collapsible-header">
          Assigned Devices {isAssignedDevicesExpanded ? '▼' : '▶'}
        </h2>
        {isAssignedDevicesExpanded && (
          <div className="assigned-devices-container">
            <div className="device-list scrollable">
              {assignedDevices.map((device) => (
                <div key={device.deviceId} className="device-item">
                  <input
                    type="checkbox"
                    checked={selectedAssignedDevices.includes(device.deviceId)}
                    onChange={() => handleAssignedDeviceSelection(device.deviceId)}
                  />
                  <span>
                    {device.deviceId} - Assigned to: {device.userId}
                    {orgMap[device.userId] && ` (${orgMap[device.userId]})`}
                  </span>
                </div>
              ))}
            </div>
            <button
              className="device-management-btn"
              style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
              onClick={openReassignModal}
              disabled={selectedAssignedDevices.length === 0}
            >
              Reassign Selected Devices
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={showReassignModal}
        onRequestClose={() => setShowReassignModal(false)}
        contentLabel="Reassign Devices"
      >
        <h2>Reassign Devices</h2>
        <p>Enter the User ID to reassign the selected devices:</p>
        <input
          type="text"
          value={reassignUserId}
          onChange={(e) => setReassignUserId(e.target.value)}
          placeholder="Enter User ID"
        />
        <button
          className="device-management-btn"
          style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
          onClick={reassignDevices}
        >
          Reassign Devices
        </button>
        <button
          className="device-management-btn"
          style={{ fontFamily: 'Montserrat', backgroundColor: '#cb2d3e', color: 'white' }}
          onClick={() => setShowReassignModal(false)}
        >
          Cancel
        </button>
      </Modal>
      {isUserIdValid && (
        <div className="device-management-shift-controls">
          <button
            className="device-management-btn"
            style={{ fontFamily: 'Montserrat', backgroundColor: '#28a745', color: 'white' }}
            onClick={() => handleShiftAction('start')}
          >
            Start All Shifts
          </button>
          <button
            className="device-management-btn"
            style={{ fontFamily: 'Montserrat', backgroundColor: '#dc3545', color: 'white' }}
            onClick={() => handleShiftAction('stop')}
          >
            Stop All Shifts
          </button>
        </div>
      )}

      <Modal
        isOpen={showShiftConfirmModal}
        onRequestClose={() => setShowShiftConfirmModal(false)}
        contentLabel="Confirm Shift Action"
      >
        <h2>Confirm {shiftAction === 'start' ? 'Start' : 'Stop'} Shifts</h2>
        <div className="device-management-confirmation-details">
          <p>Organization: {orgName}</p>
          <p>The following devices will be affected:</p>
          <div className="device-management-confirmation-details-device-list">
            {assignedDevices
              .filter((device) => device.userId === userId)
              .map((device) => (
                <div key={device.deviceId} className="device-item">
                  {device.deviceId}
                </div>
              ))}
          </div>
        </div>
        <div className="modal-buttons">
          <button
            className="device-management-btn"
            style={{ fontFamily: 'Montserrat', backgroundColor: '#6c757d', color: 'white' }}
            onClick={() => setShowShiftConfirmModal(false)}
          >
            Cancel
          </button>
          <button
            className="device-management-btn"
            style={{
              fontFamily: 'Montserrat',
              backgroundColor: shiftAction === 'start' ? '#28a745' : '#dc3545',
              color: 'white',
            }}
            onClick={executeShiftAction}
          >
            Confirm {shiftAction === 'start' ? 'Start' : 'Stop'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeviceManagement;
