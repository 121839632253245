// Citation.js
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React, { useState } from 'react';

/**
 * Converts numeric seconds into either H:MM:SS or M:SS format.
 * Examples:
 *  -  75  -> "1:15"
 *  - 3600 -> "1:00:00"
 */
function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  if (h > 0) {
    // e.g. 1:01:05 for 3665 seconds
    return `${h}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
  } else {
    // e.g. 1:15 for 75 seconds
    return `${m}:${String(s).padStart(2, '0')}`;
  }
}

/**
 * React component for rendering a single "citation" node.
 * Displays only the first timecode in red brackets, e.g. "[3:45]".
 */
function CitationComponent(props) {
  const { node, extension } = props;
  const timecodes = node.attrs.timecodes || [];
  const firstTimecode = timecodes[0] || 0;

  const [hover, setHover] = useState(false);

  const handleClick = () => {
    if (extension.options.onCitationClick) {
      extension.options.onCitationClick(firstTimecode);
    }
  };

  return (
    <NodeViewWrapper
      as="span"
      style={{
        backgroundColor: hover ? '#ffe0e0' : '#ffe5e5',
        color: '#b22',
        borderRadius: '3px',
        padding: '0 3px',
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'background-color 0.2s ease',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
      className="citation-node"
    >
      [{formatTime(firstTimecode)}]
    </NodeViewWrapper>
  );
}

export const Citation = Node.create({
  name: 'citation',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  /**
   * Allows us to pass an onCitationClick callback from the editor setup
   * e.g., to jump a video player.
   */
  addOptions() {
    return {
      onCitationClick: null, // default to no-op
    };
  },

  addAttributes() {
    return {
      timecodes: {
        default: [],
      },
    };
  },

  /**
   * parseHTML: Tells Tiptap how to parse <span data-citation data-timecodes="...">
   * from your replaced HTML into node.attrs.timecodes.
   */
  parseHTML() {
    return [
      {
        tag: 'span[data-citation]',
        getAttrs: (element) => {
          const raw = element.getAttribute('data-timecodes') || '';
          if (!raw.trim()) return {};

          // We'll only use the first timecode
          const firstTimecode = parseInt(raw.split(',')[0].trim(), 10);
          return { timecodes: [firstTimecode] };
        },
      },
    ];
  },

  /**
   * renderHTML: If someone calls editor.getHTML(), produce HTML that also
   * shows the bracketed time for the *first* code.
   * (We still store the entire array in data-timecodes for re-parsing.)
   */
  renderHTML({ node, HTMLAttributes }) {
    const { timecodes = [] } = node.attrs;
    const first = timecodes[0] || 0;

    const formatted = formatTime(first);

    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-citation': '',
        'data-timecodes': first.toString(),
        style: [
          'background-color: #ffe5e5',
          'color: #b22',
          'cursor: pointer',
          'border-radius: 3px',
          'padding: 0 3px',
        ].join('; '),
      }),
      `[${formatted}]`,
    ];
  },
  /**
   * Tiptap-specific: allows us to render the React component above.
   */
  addNodeView() {
    return ReactNodeViewRenderer(CitationComponent);
  },
});
