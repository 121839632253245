import '../../../styles/NewDashboard/DashboardControls/DateControl.css';
import { startOfWeek, endOfWeek, subWeeks, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { trackTimePeriodChange } from '../../../utils/analytics';

const DateControl = ({ currentTimePeriod, setCurrentTimePeriod }) => {
  const handleWeekChange = (direction) => {
    const currentWeeksBack = currentTimePeriod.weeksBack || 0;
    const newWeeksBack = direction === 'next' ? currentWeeksBack - 1 : currentWeeksBack + 1;
    const startDate = startOfWeek(subWeeks(new Date(), newWeeksBack), { weekStartsOn: 1 });
    const endDate = endOfWeek(subWeeks(new Date(), newWeeksBack), { weekStartsOn: 1 });

    const newPeriod = {
      name: newWeeksBack === 0 ? 'thisWeek' : `week${newWeeksBack}`,
      weeksBack: newWeeksBack,
      startDate: startDate,
      endDate: endDate,
      key: `${startDate.toISOString()}_${endDate.toISOString()}`,
    };

    setCurrentTimePeriod(newPeriod);
    trackTimePeriodChange(
      newWeeksBack === 0 ? 'thisWeek' : `week${newWeeksBack}`,
      currentWeeksBack === 0 ? 'thisWeek' : `week${currentWeeksBack}`
    );
  };

  const getWeekLabel = (weeksBack) => {
    if (weeksBack === 0) return 'This Week';
    if (weeksBack === 1) return 'Last Week';
    return `${weeksBack} Weeks Ago`;
  };

  return (
    <div className="date-control-container">
      <div className="date-range-control">
        <button className="arrow-button" onClick={() => handleWeekChange('prev')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="date-range-display">
          <div className="date-range">
            {format(currentTimePeriod.startDate, 'MMM d')} - {format(currentTimePeriod.endDate, 'MMM d')}
          </div>
          <div className="week-label">{getWeekLabel(currentTimePeriod.weeksBack || 0)}</div>
        </div>
        <button
          className="arrow-button"
          onClick={() => handleWeekChange('next')}
          disabled={currentTimePeriod.weeksBack <= 0}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default DateControl;
